import Alpine from 'alpinejs';
import AlpineFocus from '@alpinejs/focus'
import AlpineUI from '@alpinejs/ui'
import glide from './components/glide.js'

// Components
Alpine.data('glide', glide)

// Global
Alpine.plugin(AlpineFocus);
Alpine.plugin(AlpineUI);

window.Alpine = Alpine;

// Inits
Alpine.start();